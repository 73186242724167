import { Outlet } from 'react-router-dom';
import Header from './Navbar/Header'

const GuestLayout = ({ children, isLoggedIn, setLoggedIn }) => {
    return (
        <div>
            <Header isLoggedIn={isLoggedIn} setLoggedIn={setLoggedIn} />
            <Outlet /> {children}
        </div>
    );
};

export default GuestLayout;

// const GuestLayout = ({ isLoggedIn, setLoggedIn }) => {
//     return (
//         <div>
//             <Header isLoggedIn={isLoggedIn} setLoggedIn={setLoggedIn} />
//             <Outlet /> {/* Renderiza as rotas aninhadas */}
//         </div>
//     );
// };