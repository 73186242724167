import * as React from 'react';
import Typography from '@mui/material/Typography';
import { Alert, Box, Button, Checkbox, Container, FormControl, Input, InputLabel, List, ListItemText, MenuItem, Modal, Pagination, PaginationItem, Paper, Select, Snackbar, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, colors } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import { Link, useLocation, useNavigate } from 'react-router-dom'
import axios from 'axios';
import { PAGE_SIZE, url } from '../utils/constants';
import { LoaderOverlay } from '../utils/components';
import Sidebar from '../utils/SideBar';
import Sidebar2 from '../utils/SideBar2';
import FilterComponent from '../utils/FilterComponent';
import FilterComponentVertical from '../utils/FilterComponentVertical';



export default function PerfilList({ setIsLoggedIn, usePageTitle }) {
  usePageTitle('Pixtick | Perfis');
  const [perfis, setPerfis] = React.useState([]);
  const [currentPage, setCurrentPage] = React.useState(1);
  const [totalPages, setTotalPages] = React.useState(1);

  const [loading, setLoading] = React.useState(true);
  const navigate = useNavigate();
  const location = useLocation();
  const [filters, setFilters] = React.useState({
    descricao: '',
    nome: '',
    dataInicio: '',
    dataFim: '',
    telefone: '',
    id: '',
    idTransacao: '',
    idpdvs: [],
    status: 'Todos',
    geral: ''
  });
  const [selectedPerfil, setSelectedPerfil] = React.useState(null);

  React.useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const page = parseInt(queryParams.get('page')) || 1;
    setCurrentPage(page);
  }, [location.search]);

  React.useEffect(() => {
    async function fetchData() {
      try {
        const token = await localStorage.getItem('token');
        const queryParams = new URLSearchParams({
          page: currentPage,
          ...filters,
          status: filters.status === 'Todos' ? '' : filters.status
        });

        const response = await axios.get(url + `/api/v1/user/perfis?${queryParams}`, {
          headers: ({
            Authorization: 'Bearer ' + token
          })
        });
        const { data, totalcount } = response.data;

        console.log(totalcount);

        setPerfis(data);

        setTotalPages(Math.ceil(totalcount / PAGE_SIZE));

        //console.log(data);


        setIsLoggedIn(true);
      } catch {
        setIsLoggedIn(false);
        navigate('/signin');
      } finally {
        setLoading(false);
        //console.log(transactions);

      }

    }
    fetchData();
  }, [navigate, setIsLoggedIn, currentPage]);

  const handlePageChange = (event, pageNumber) => {
    setCurrentPage(pageNumber);

    navigate(`?page=${pageNumber}`);
  };



  function formatPhoneNumber(phoneNumber) {
    if (!phoneNumber) return phoneNumber;

    // Definir máscaras para cada DDI
    const masks = {
      '+55': { name: 'Brasil', mask: '(xx) xxxxx-xxxx' },
      '+54': { name: 'Argentina', mask: '(x) xxxx xx-xxxx' },
      '+595': { name: 'Paraguai', mask: '(xxx) xxx-xxx' },
      '+1': { name: 'EUA', mask: '(xxx) xxx-xxxx' }
      // Adicione outras máscaras conforme necessário
    };

    // Detectar o DDI do número de telefone
    let ddi = Object.keys(masks).find(ddi => phoneNumber.startsWith(ddi));
    if (!ddi) return phoneNumber; // Se não for encontrado nenhum DDI correspondente, retorne o número de telefone sem formatação
    phoneNumber = phoneNumber.replace(ddi, ''); // Remover o DDI do número de telefone
    // Obter a máscara correspondente ao DDI detectado
    const mask = masks[ddi].mask;

    // Remover caracteres não numéricos do número de telefone
    const numericPhoneNumber = phoneNumber.replace(/\D/g, '');

    // Aplicar máscara ao número de telefone
    let formattedPhoneNumber = '';
    let maskIndex = 0;
    for (let i = 0; i < mask.length && maskIndex < numericPhoneNumber.length; i++) {
      if (mask[i] === 'x') {
        formattedPhoneNumber += numericPhoneNumber[maskIndex];
        maskIndex++;
      } else {
        formattedPhoneNumber += mask[i];
      }
    }
    //ddi + formattedPhoneNumber;

    return ddi + ' ' + formattedPhoneNumber;
  }

  const formatCurrency = (value) => {
    if (!value) return value;

    // Formata o valor em moeda brasileira (pt-BR)
    return parseFloat(value).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
  };

  function getStatusColor(status) {
    switch (status) {
      case 'EMANALISE':
        return '#0000FF'; // Amarelo
      case 'ATIVO':
        return '#008000'; // Verde
      case 'Concluído':
        return '#0000FF'; // Azul
      case 'Falhou':
        return '#FF0000'; // Vermelho
      default:
        return '#FF0000'; // Cinza (ou qualquer outra cor padrão)
    }
  }

  const handlePerfilClick = (perfil) => {
    console.log(perfil);
    navigate(`/perfil-detalhado/${perfil._id}`)
    //setSelectedTransaction(transaction);
  };

  const handleCloseModal = () => {
    setSelectedPerfil(null);
  };

  const handlePixTransactionClick = (transaction) => {
    console.log(transaction);

  }

  const handleFilter = async () => {
    setLoading(true);
    setCurrentPage(1);

    try {
      const token = await localStorage.getItem('token');
      const queryParams = new URLSearchParams({
        page: currentPage,
        ...filters,
        idpdvs: filters.idpdvs ? filters.idpdvs.join(',') : '',
        status: filters.status === 'Todos' ? '' : filters.status
      });
      console.log('teste> ' + filters.geral);
      const response = await axios.get(url + `/api/v1/user/perfis?${queryParams}`, {
        headers: ({
          Authorization: 'Bearer ' + token
        })
      });
      const { data, totalcount } = response.data;

      setPerfis(data);
      setTotalPages(Math.ceil(totalcount / PAGE_SIZE));

      //console.log(data);

      setIsLoggedIn(true);
    } catch {
      setIsLoggedIn(false);
      navigate('/signin');
    } finally {
      setLoading(false);
    };
  }




  const handleChangeStatus = async (perfil) => {
    setLoading(true);
    try {
      const token = await localStorage.getItem('token');
      const statusTemp = perfil.status;
      perfil.status = perfil.status == 'ATIVO' ? 'INATIVO' : perfil.status == 'INATIVO' ? 'ATIVO' : perfil.status;
      if (statusTemp != perfil.status) {
        const response = await axios.post(url + `/api/v1/user/atualizaStatus/${perfil._id}`, { status: perfil.status }, {
          headers: ({
            Authorization: 'Bearer ' + token
          })
        });
        const { data } = response.data;
      }
    } catch {
      navigate('/signin');
    } finally {
      setLoading(false);
    };
  }


  return (
    <Container >
      {loading ? <LoaderOverlay loading={loading} /> :
        <Grid container spacing={2} sx={{ marginTop: 10, justifyContent: 'center' }}  >
          <Grid item xs={12} sx={{ marginBottom: 2 }}>
            <Typography variant="h4" fontWeight={'bold'} fontFamily={'Ubuntu'} component="h1" sx={{ display: 'flex', justifyContent: 'center' }}>Perfis</Typography>
          </Grid>

          <Grid item xs={12} sx={{ display: { xs: 'flex', md: 'none' }, justifyContent: 'center', marginTop: 2 }}>
            {/* <Sidebar filters={filters} setFilters={setFilters} onFilter={handleFilter} idpdvs={idpdvs} /> */}

          </Grid>
          <Grid item xs={12} sx={{ display: { xs: 'none', md: 'flex' }, marginTop: 6 }}>

            {/* <FilterComponent filters={filters} setFilters={setFilters} onFilter={handleFilter} idpdvs={idpdvs} /> */}
            {/* <Sidebar2 open={true} filters={filters} setFilters={setFilters} onFilter={handleFilter} idpdvs={idpdvs} /> */}

          </Grid>


          <Grid item xs={12} md={10} sx={{ display: 'flex', justifyContent: 'right' }} alignSelf='end' justifySelf={'end'}>
            <List sx={{ marginTop: 1 }}>

              {perfis.length > 0 ? (
                <>
                  <TableContainer sx={{ display: { xs: 'none', md: 'flex' }, borderSpacing: '0 8px' }} >
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell sx={{ fontWeight: 'bold' }}>Nome</TableCell>
                          <TableCell sx={{ fontWeight: 'bold' }}>Empresa</TableCell>
                          <TableCell sx={{ fontWeight: 'bold' }}>idpdv</TableCell>
                          <TableCell sx={{ fontWeight: 'bold' }}>Telefone</TableCell>
                          <TableCell sx={{ fontWeight: 'bold' }}>email</TableCell>
                          <TableCell sx={{ fontWeight: 'bold' }}>data/hora</TableCell>
                          <TableCell align='center' sx={{ fontWeight: 'bold' }}>Status</TableCell>

                        </TableRow>
                      </TableHead>
                      <TableBody >
                        {perfis.map((perfil) => (
                          <TableRow key={perfil._id} >
                            <TableCell onClick={() => handlePerfilClick(perfil)}>{perfil.fullname ? perfil.fullname : '-'}</TableCell>
                            <TableCell onClick={() => handlePerfilClick(perfil)}>{perfil.empresa ? perfil.empresa : '-'}</TableCell>
                            <TableCell onClick={() => handlePerfilClick(perfil)}>{perfil.idpdv ? perfil.idpdv : '-'}</TableCell>
                            <TableCell onClick={() => handlePerfilClick(perfil)}>{formatPhoneNumber('+' + perfil.numero)}</TableCell>
                            <TableCell onClick={() => handlePerfilClick(perfil)}>{perfil.email ? perfil.email : '-'}</TableCell>
                            <TableCell onClick={() => handlePerfilClick(perfil)}>{perfil.createdDate ? new Date(perfil.createdDate).toLocaleDateString('pt-BR', { day: '2-digit', month: '2-digit', year: 'numeric', hour: '2-digit', minute: '2-digit', second: '2-digit' }) : '-'}</TableCell>
                            <TableCell style={{ color: perfil.status == 'ATIVO' ? 'green' : perfil.status == 'EMANALISE' ? 'blue' : 'red' }} onClick={() => handleChangeStatus(perfil)}>
                              <Button
                                variant="contained"
                                size=""
                                sx={{
                                  backgroundColor: getStatusColor(perfil.status), // Função getStatusColor para determinar a cor com base no status
                                  color: '#fff', // Cor do texto do botão
                                  border: 'none', // Remover borda
                                  borderRadius: '20px', // Borda arredondada
                                  padding: '8px 16px', // Preenchimento interno
                                  pointerEvents: 'none', // Desabilitar eventos de clique
                                  width: '110px' // Definir a largura do botão
                                }}

                              >
                                {perfil.status ? (perfil.status == 'ATIVO' ? perfil.status : perfil.status == 'EMANALISE' ? 'EM ANALISE' : perfil.status == 'INATIVO' ? 'INATIVO' : 'BLOQUEADO') : '-'}
                              </Button>
                            </TableCell>


                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>

                  {perfis.map((perfil) => (
                    <Card key={perfil._id} variant="outlined" sx={{ width: '100%', display: { xs: 'flex', md: 'none' }, marginBottom: 2 }} onClick={() => handlePerfilClick(perfil)}>
                      <CardContent sx={{ width: '100%' }}>
                        <Grid container spacing={2} sx={{ marginRight: 1 }}>
                          {/* Primeira coluna para o status */}
                          <Grid alignContent={'center'} justifyContent={'flex-start'} item xs={3}>
                            <Button
                              variant="contained"
                              size=""
                              sx={{
                                backgroundColor: getStatusColor(perfil.status),
                                color: '#fff',
                                border: 'none',
                                borderRadius: '20px',
                                padding: '8px 16px',
                                pointerEvents: 'none',
                                width: '90px',
                                fontSize: '0.8rem'
                              }}
                            >
                              {perfil.status}
                            </Button>
                          </Grid>
                          {/* Segunda coluna para nome, valor e descrição */}
                          <Grid container xs={9} spacing={2} sx={{ marginTop: 0.01 }} justifyContent={'flex-end'} alignContent={'center'} >
                            <Grid item xs={6}>
                              <Typography variant="body1" sx={{ fontSize: '0.9rem', fontWeight: 'bold', textAlign: 'left' }} component="div">{perfil.nomeCompleto}</Typography>
                            </Grid>
                            <Grid item xs={5}>
                              <Typography variant="body1" sx={{ fontSize: '0.9rem', fontWeight: 'bold', textAlign: 'right' }}> {formatCurrency(perfil.idpdv)}</Typography>
                            </Grid>
                            <Grid item xs={11}>
                              <Typography variant="body2" sx={{ fontSize: '0.9rem' }}>Descrição: {perfil.createdDate}</Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                      </CardContent>
                    </Card>

                  ))}

                </>
              ) : (
                <Typography component="h2" variant="h5">Nenhum pagamento encontrado</Typography>
              )
              }

            </List >
          </Grid>
          <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center', marginTop: 2 }}>
            <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
              <Pagination
                count={totalPages}
                page={currentPage}
                onChange={handlePageChange}
                renderItem={(item) => (
                  <PaginationItem
                    component="button"
                    {...item}
                    onClick={(event) => handlePageChange(event, item.page)}
                  />
                )}
              />
            </Box>
          </Grid>
        </Grid>}

    </Container >
  );

}

