import { createTheme, ThemeProvider } from '@mui/material/styles';

const theme = createTheme({
  typography: {
    fontFamily: [
      'Ubuntu', // Substitua 'Ubuntu' pelo nome da fonte que você está usando
      'sans-serif',
    ].join(','),
  },
});

export default theme;