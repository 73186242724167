export const departamentos = [{ 'descripcion': 'ALTO PARANA', 'codigo': 11 }];
// export const url = "http://localhost:3002";
export const url = "https://pixtick.com.br/api";
// export const url = "https://factura.mercadot.com.br/api";
export const masks = {
    '+55': { name: 'Brasil', mask: '(xx) xxxxx-xxxx' },
    '+54': { name: 'Argentina', mask: '(x) xxxx xx-xxxx' },
    '+595': { name: 'Paraguay', mask: '(xxx) xxx-xxx' },
    '+1': { name: 'EUA', mask: '(xxx) xxx-xxxx' }
    // Adicione outras máscaras conforme necessário
};

//export const roles = ['MASTER', 'ADMIN', 'USER', 'GUEST'];
export const roles = ['ADMIN', 'USER'];

export const nomeHost = "Pixtick";
export const PAGE_SIZE = 20;