import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { roles, url } from '../utils/constants';
import Telefone from '../utils/Telefone';
import { validateInput } from '../utils/validates';
import { Backdrop, CircularProgress, FormControl, IconButton, InputAdornment, InputLabel, makeStyles, MenuItem, Select } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import e from 'cors';




function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://mui.com/">
        factura.mercadot.com.br
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const theme = createTheme();



export default function SignUp({ setIsLoggedIn, usePageTitle }) {
  usePageTitle('Pixtick | Signup');
  const [role, setRole] = React.useState('USER');
  const [showPassword, setShowPassword] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  let navigate = useNavigate();

  React.useEffect(() => {
    async function fetchData() {
      try {
        const token = await localStorage.getItem('token');
        console.log('teste> ' + token);
        if (token) {
          const { data } = await axios.get(url + '/api/v1/user/verifyToken', {
            headers: ({
              Authorization: 'Bearer ' + token
            })
          });

          setIsLoggedIn(true);

        } else {
          setIsLoggedIn(false);
          navigate('/signin');
        }
      } catch {
        setIsLoggedIn(false);
        navigate('/signin');

      } finally {
        setLoading(false);
      }

    }
    fetchData();
  }, [navigate, setIsLoggedIn]);

  const [state, setState] = React.useState({
    ddi: '+55',
    phone: '',
    error: '',
    fname: '',
    lname: '',
    email: '',
    password: '',
    empresa: ''
  });

  const handleSubmit = async (event) => {
    event.preventDefault();
    let error = '';
    error = validateInput(state.phone, state.ddi);
    setState({ ...state, error: error });
    if (!error) {
      setLoading(true);
      try {
        const token = await localStorage.getItem('token');
        //const form = new FormData(event.target);
        const data = new FormData(event.Target);
        const form = {
          fullname: state.fname + ' ' + state.lname,
          email: state.email,
          numero: (state.ddi + state.phone).replace(/[()\s+-]/g, ""),
          password: state.password,
          role: role,
          empresa: state.empresa
        };
        const headers = {
          Authorization: 'Bearer ' + token
        }
        console.log(headers.Authorization);
        await axios.post(url + "/api/v1/user/signup", form, { headers: headers })
          .then(response => {
            navigate('/signin')
          })
          .catch(error => {
            /*setState({ ...state, error: error })*/
            const msgDRetorno = (error.response.data.message);
            setState({ ...state, error: msgDRetorno })
            console.log(error);
          });
        setLoading(false);
      } catch (error) {
        console.error(error);
        setState({ ...state, error: error });
        setLoading(false);
      }
    }
  };

  const handleRoleChange = (event) => {
    const value = event.target.value
    setRole(value);
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >


          <Typography component="h1" variant="h5" sx={{ marginTop: 2 }}>
            Novo Usuário
          </Typography>
          <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  autoComplete="given-name"
                  name="fname"
                  required
                  fullWidth
                  id="fname"
                  label="Nome"
                  autoFocus
                  value={state.fname}
                  InputProps={{
                    style: {
                      borderRadius: '20px', // Aqui você define o borderRadius desejado
                    }
                  }}
                  onChange={(e) => setState({ ...state, fname: e.target.value })}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  fullWidth
                  id="lname"
                  label="Sobrenome"
                  name="lname"
                  autoComplete="family-name"
                  value={state.lname}
                  InputProps={{
                    style: {
                      borderRadius: '20px', // Aqui você define o borderRadius desejado
                    }
                  }}
                  onChange={(e) => setState({ ...state, lname: e.target.value })}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  id="empresa"
                  label="Empresa"
                  name="empresa"
                  autoComplete="empresa"
                  value={state.empresa}
                  InputProps={{
                    style: {
                      borderRadius: '20px', // Aqui você define o borderRadius desejado
                    }
                  }}
                  onChange={(e) => setState({ ...state, empresa: e.target.value })}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  id="email"
                  label="Email"
                  name="email"
                  autoComplete="email"
                  value={state.email}
                  InputProps={{
                    style: {
                      borderRadius: '20px', // Aqui você define o borderRadius desejado
                    }
                  }}
                  onChange={(e) => setState({ ...state, email: e.target.value })}
                />
              </Grid>
              <Grid item xs={12}>
                <Telefone
                  state={state} setState={setState}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  name="password"
                  label="Senha"
                  type={showPassword ? 'text' : 'password'}
                  id='password'
                  value={state.password}

                  onChange={(e) => setState({ ...state, password: e.target.value })}
                  InputProps={{
                    style: {
                      borderRadius: '20px', // Aqui você define o borderRadius desejado
                    },

                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={togglePasswordVisibility}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    )
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth sx={{ mr: 2 }}>
                  <InputLabel id="role-label">Nivel de acesso</InputLabel>
                  <Select
                    sx={{ borderRadius: 5 }}
                    labelId="role-label"
                    id="role"
                    name="role"
                    value={role}
                    onChange={handleRoleChange}
                  >
                    {roles.map((role) => (
                      <MenuItem key={role} value={role}>
                        {role}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

            </Grid>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ borderRadius: 2.5, mt: 3, mb: 2 }}
            >
              Cadastrar
            </Button>

            <Grid item>{state.error && <p style={{ color: 'red' }}>{state.error}</p>}</Grid>
          </Box>
        </Box>

        {loading && (
          <div style={{ position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', backgroundColor: 'rgba(255, 255, 255, 0.8)', display: 'flex', alignItems: 'center', justifyContent: 'center', zIndex: 9999 }}>
            <CircularProgress />
          </div>
        )}
      </Container>
    </ThemeProvider>
  );
}