// FilterComponent.js
import React from 'react';
import { TextField, Box, Grid, Select, MenuItem, Button, InputLabel, FormControl, Checkbox, ListItemText, Input } from '@mui/material';

const FilterComponent = ({ filters, setFilters, onFilter, idpdvs }) => {

  const handleSelectChange = (event) => {
    const value = event.target.value;
    if (value[value.length - 1] === 'all') {
      setFilters((prevFilters) => ({
        ...prevFilters,
        idpdvs: filters.idpdvs.length === idpdvs.length ? [] : idpdvs.map((user) => user.idpdv),
      }));
    } else {
      setFilters((prevFilters) => ({
        ...prevFilters,
        idpdvs: value,
      }));
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onFilter();
  }

  const isAllSelected = filters.idpdvs.includes('all');

  return (
    <form onSubmit={handleSubmit}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={4} lg={4}>
          <TextField
            fullWidth
            label="Descrição"
            variant="outlined"
            value={filters.descricao}
            onChange={(e) => setFilters({ ...filters, descricao: e.target.value })}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={4}>
          <TextField
            fullWidth
            label="Nome"
            variant="outlined"
            value={filters.nome}
            onChange={(e) => setFilters({ ...filters, nome: e.target.value })}
          />
        </Grid>
        <Grid item xs={6} sm={6} md={4} lg={2}>
          <TextField
            fullWidth
            label="Data Início"
            type="date"
            variant="outlined"
            value={filters.dataInicio}
            onChange={(e) => setFilters({ ...filters, dataInicio: e.target.value })}
            InputLabelProps={{
              shrink: true,
            }}
            style={{ marginRight: '10px' }}
          />
        </Grid>
        <Grid item xs={6} sm={6} md={4} lg={2}>
          <TextField
            fullWidth
            label="Data Fim"
            type="date"
            variant="outlined"
            value={filters.dataFim}
            onChange={(e) => setFilters({ ...filters, dataFim: e.target.value })}
            InputLabelProps={{
              shrink: true,
            }}
            style={{ marginRight: '10px' }}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={4}>
          <TextField
            fullWidth
            label="Telefone"
            variant="outlined"
            value={filters.telefone}
            onChange={(e) => setFilters({ ...filters, telefone: e.target.value })}
            style={{ marginRight: '10px' }}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={2}>
          <Select
            fullWidth
            labelId='status-select-label'
            label="Status"
            variant="outlined"
            value={filters.status}
            onChange={(e) => setFilters({ ...filters, status: e.target.value })}
            style={{ marginRight: '10px' }}
          >
            <MenuItem value="Todos">Todos</MenuItem>
            <MenuItem value="PAGO">RECEBIDO</MenuItem>
            <MenuItem value="Pendente">Pendente</MenuItem>
            <MenuItem value="Expirado">Expirado</MenuItem>
          </Select>
        </Grid>
        {localStorage.getItem('role') === 'ADMIN' && <Grid item xs={12} sm={6} md={4} lg={4}>
          {(
            <FormControl fullWidth>
              <InputLabel id="idpdvs-select-label">IDPDVs</InputLabel>
              <Select
                labelId="idpdvs-select-label"
                id="idpdvs-select"
                multiple
                value={filters.idpdvs}
                onChange={handleSelectChange}
                input={<Input />}
                renderValue={(selected) => selected.join(', ')}
              >
                <MenuItem value="all">
                  <Checkbox checked={isAllSelected} indeterminate={filters.idpdvs.length > 0 && !isAllSelected} />
                  <ListItemText primary="Marcar Todos" />
                </MenuItem>
                {idpdvs?.map((user) => (
                  <MenuItem key={user.idpdv} value={user.idpdv}>
                    <Checkbox checked={filters.idpdvs.indexOf(user.idpdv) > -1} />
                    <ListItemText primary={`${user.empresa} - ${user.idpdv}`} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
        </Grid>}
        <Grid item xs={12} sm={6} md={4} lg={2}>
          <Button fullWidth type='submit' variant="contained">Filtrar</Button>
        </Grid>
        {/* Repita para os outros campos */}
      </Grid>
    </form>
  );
};

export default FilterComponent; 