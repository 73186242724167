import * as React from 'react';
import Typography from '@mui/material/Typography';
import { Alert, Box, Button, Container, Paper, Snackbar } from '@mui/material';

import Grid from '@mui/material/Grid';
import { useLocation, useNavigate } from 'react-router-dom'
import axios from 'axios';
import { url } from '../utils/constants';
import { LoaderOverlay } from '../utils/components';
import QRCode from 'qrcode.react';



export default function PagamentoDetalhado({ setIsLoggedIn, usePageTitle }) {
  usePageTitle('Pixtick | Transação detalhada');
  const id = useLocation().pathname.split('/').pop();
  const [transaction, setTransaction] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const [copied, setCopied] = React.useState(false);
  const navigate = useNavigate();


  React.useEffect(() => {
    async function fetchData() {
      try {
        const token = await localStorage.getItem('token');
        //console.log('teste> ' + token);
        const response = await axios.get(url + `/api/v1/pagamento/${id}`, {
          headers: ({
            Authorization: 'Bearer ' + token
          })
        });
        const { data } = response.data;
        setTransaction(data);

        //console.log(data);

        setIsLoggedIn(true);
      } catch {
        setIsLoggedIn(false);
        //navigate('/signin');
      } finally {
        setLoading(false);
        //console.log(transactions);

      }

    }
    fetchData();
  }, [navigate, setIsLoggedIn, transaction._id]);





  function formatPhoneNumber(phoneNumber) {
    if (!phoneNumber) return phoneNumber;

    // Definir máscaras para cada DDI
    const masks = {
      '+55': { name: 'Brasil', mask: '(xx) xxxxx-xxxx' },
      '+54': { name: 'Argentina', mask: '(x) xxxx xx-xxxx' },
      '+595': { name: 'Paraguai', mask: '(xxx) xxx-xxx' },
      '+1': { name: 'EUA', mask: '(xxx) xxx-xxxx' }
      // Adicione outras máscaras conforme necessário
    };

    // Detectar o DDI do número de telefone
    let ddi = Object.keys(masks).find(ddi => phoneNumber.startsWith(ddi));
    if (!ddi) return phoneNumber; // Se não for encontrado nenhum DDI correspondente, retorne o número de telefone sem formatação
    phoneNumber = phoneNumber.replace(ddi, ''); // Remover o DDI do número de telefone
    // Obter a máscara correspondente ao DDI detectado
    const mask = masks[ddi].mask;

    // Remover caracteres não numéricos do número de telefone
    const numericPhoneNumber = phoneNumber.replace(/\D/g, '');

    // Aplicar máscara ao número de telefone
    let formattedPhoneNumber = '';
    let maskIndex = 0;
    for (let i = 0; i < mask.length && maskIndex < numericPhoneNumber.length; i++) {
      if (mask[i] === 'x') {
        formattedPhoneNumber += numericPhoneNumber[maskIndex];
        maskIndex++;
      } else {
        formattedPhoneNumber += mask[i];
      }
    }
    //ddi + formattedPhoneNumber;

    return ddi + ' ' + formattedPhoneNumber;
  }

  const formatCurrency = (value) => {
    if (!value) return value;

    // Formata o valor em moeda brasileira (pt-BR)
    return parseFloat(value).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
  };

  function getStatusColor(status) {
    switch (status) {
      case 'Pendente':
        return '#1976D2'; // Amarelo
      case 'PAGO':
        return '#008000'; // Verde
      case 'Concluído':
        return '#0000FF'; // Azul
      case 'Falhou':
        return '#FF0000'; // Vermelho
      default:
        return '#FF0000'; // Cinza (ou qualquer outra cor padrão)
    }
  }


  const handleVoltar = () => {
    navigate('/relatorio-pagamento');
  }

  return (

    <Container>
      <LoaderOverlay loading={loading} />

      <Box marginTop={10} justifyContent='center' >

        <Paper
          elevation={3}
          sx={{
            marginTop: 10, padding: 2, width: { xs: '90%', sm: '75%', md: '50%', lg: '33%' },
            marginLeft: { xs: 'auto', sm: 'auto', md: 'auto', lg: 'auto' }, // Centraliza horizontalmente em xs e sm
            marginRight: { xs: 'auto', sm: 'auto', md: 'auto', lg: 'auto' }, // Centraliza horizontalmente em xs e sm
          }}>
          {!loading && (
            <>
              <Typography variant="h6" component="div" align='center' >Detalhes da Transação</Typography>
              <Grid container spacing={2} sx={{ marginTop: 2 }}>

                <Grid item xs={5}>
                  <Typography variant="body1">Nome:</Typography>
                </Grid>
                <Grid item xs={7}>
                  <Typography variant="body1" sx={{ textAlign: 'right' }}>{transaction.nomeCompleto ? transaction.nomeCompleto : '-'}</Typography>

                </Grid>
                <Grid item xs={5}>
                  <Typography variant="body1">Descrição:</Typography>
                </Grid>
                <Grid item xs={7}>
                  <Typography variant="body1" sx={{ textAlign: 'right' }}>{transaction.description ? transaction.description : '-'}</Typography>

                </Grid>
                <Grid item xs={5}>
                  <Typography variant="body1">Data / Hora:</Typography>
                </Grid>
                <Grid item xs={7}>
                  <Typography variant="body1" sx={{ textAlign: 'right' }}>{transaction.date ? new Date(transaction.date).toLocaleDateString('pt-BR', { day: '2-digit', month: '2-digit', year: 'numeric', hour: '2-digit', minute: '2-digit', second: '2-digit' }) : '-'}</Typography>

                </Grid>
                <Grid item xs={5}>
                  <Typography variant="body1">Telefone:</Typography>
                </Grid>
                <Grid item xs={7}>
                  <Typography variant="body1" sx={{ textAlign: 'right' }}>{transaction.wppNumber ? formatPhoneNumber('+' + transaction.wppNumber) : '-'}</Typography>

                </Grid>
                <Grid item xs={5}>
                  <Typography variant="body1">ID:</Typography>
                </Grid>
                <Grid item xs={7}>
                  <Typography variant="body1" sx={{ textAlign: 'right' }}>{transaction.id ? transaction.id : '-'}</Typography>

                </Grid>
                <Grid item xs={5}>
                  <Typography variant="body1">ID Transação:</Typography>
                </Grid>
                <Grid item xs={7}>
                  <Typography variant="body1" sx={{ textAlign: 'right' }}>{transaction.idTransaction ? transaction.idTransaction : '-'}</Typography>

                </Grid>
                <Grid item xs={5}>
                  <Typography variant="body1">Valor:</Typography>
                </Grid>
                <Grid item xs={7}>
                  <Typography variant="body1" sx={{ textAlign: 'right' }}>{transaction.amount ? formatCurrency(transaction.amount) : '-'}</Typography>

                </Grid>
                {transaction.status == 'Pendente' && <Grid item xs={5}>
                  <Typography variant="body1">Codigo Pix:</Typography>

                </Grid>}
                {transaction.status == 'Pendente' && <Grid item xs={12}>
                  <Typography variant="p" sx={{ margin: 1, fontSize: '12px', textAlign: 'left' }}>Clique para copiar o código abaixo para realizar o pagamento!</Typography>

                </Grid>}
                {transaction.status == 'Pendente' && <Grid item xs={12}>
                  <Box sx={{ display: 'flex', justifyContent: 'center', border: '1px solid #888', borderRadius: 2, background: '#cdcdcd', opacity: 0.9 }}>

                    <Typography
                      variant="p"
                      sx={{
                        fontStyle: { color: '#000', opacity: 1 },
                        margin: 1,
                        fontSize: '12px',
                        textAlign: 'left'
                      }}
                      onClick={() => {
                        navigator.clipboard.writeText(transaction.pixCopyPaste);
                        setCopied(true); // Define o estado para indicar que o código foi copiado
                        setTimeout(() => {
                          setCopied(false); // Reseta o estado após alguns segundos
                        }, 2000);
                      }}
                    >
                      {transaction.pixCopyPaste ? transaction.pixCopyPaste : '-'}
                    </Typography>
                  </Box>

                </Grid>}
                {transaction.status == 'Pendente' && <Grid item xs={12}>
                  <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                    <QRCode size={200} value={transaction.pixCopyPaste} />
                  </Box>

                </Grid>}
                {false && <Grid item xs={5}>
                  <Typography variant="body1">Status:</Typography>
                </Grid>}
                <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                  {/* <Typography variant="body1" color={getStatusColor(transaction.status)} sx={{ textAlign: 'right' }}>{transaction.status ? transaction.status : '-'}</Typography> */}

                  <Button
                    variant="contained"
                    size=""

                    sx={{

                      backgroundColor: getStatusColor(transaction.status), // Função getStatusColor para determinar a cor com base no status
                      color: '#fff', // Cor do texto do botão
                      border: 'none', // Remover borda
                      borderRadius: '20px', // Borda arredondada

                      padding: '8px 16px', // Preenchimento interno
                      pointerEvents: 'none', // Desabilitar eventos de clique
                      width: '210px' // Definir a largura do botão
                    }}
                  >{transaction.status}</Button>

                </Grid>
                <Grid item xs={12}
                  alignContent='center'
                  justifyContent='center'
                >


                </Grid>

              </Grid>
            </>
          )}
        </Paper>

        <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 2 }}>
          <Button type='cancel' variant='contained' sx={{ width: { xs: '90%', sm: '75%', md: '50%', lg: '33%' }, borderRadius: 2.5 }} onClick={handleVoltar}>Voltar</Button>
        </Box>
      </Box>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={copied}
        autoHideDuration={2000}
        onClose={() => setCopied(false)}
      >
        <Alert onClose={() => setCopied(false)} severity="success" sx={{ width: '100%' }}>
          Código copiado!
        </Alert>
      </Snackbar>
    </Container >

  );

}
