import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { Container, Typography, Box, Button } from '@mui/material';
import axios from 'axios';

const NotFound = () => {

    const navigate = useNavigate()
    React.useEffect(() => {
        document.title = 'Not Found';
    });

    const handleGoHome = () => {
        navigate('/signin')
    }


    return (
        <Container maxWidth="sm">
            <Box marginTop={2} alignContent={'center'} sx={{ my: 4 }} p={4}>
                <Typography variant='h6' component='h1'>Ooooops pagina não encontrada!</Typography>

            </Box>
            <Box mt={2}>
                <Button type="submit" variant="contained" color="primary" onClick={handleGoHome}>Home</Button>
            </Box>

        </Container>
    );
};

export default NotFound;
