import * as React from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import GuestLayout from './GuestLayout';
import SignIn from './Auth/SignIn';
import SignUp from './Auth/SignUp';

import ForgotPassword from './Auth/ForgotPassword';
import RecoveryPassword from './Auth/RecoveryPassword';
import NotFound from './NotFound/NotFound';
import PagamentoMercadoPago from './pagamento/pagamentoMercadoPago';
import PagamentoList from './pagamento/PagamentoList';
import PagamentoDetalhado from './pagamento/PagamentoDetalhado';
import Perfil from './Perfil/Perfil';
import PerfilList from './Perfil/PerfilList';
import PerfilDetalhado from './Perfil/PerfilDetalhado';
import FinalizaPagamento from './pagamento/FinalizaPagamento';



export const routes = [
    { path: 'signin', component: SignIn, name: 'Sign In', showInMenu: false },
    { path: 'perfil', component: Perfil, name: 'Perfil', showInMenu: false },
    { path: 'perfis', component: PerfilList, name: 'Perfil', showInMenu: false },
    { path: 'perfil-detalhado/:id', component: PerfilDetalhado, name: 'Perfil', showInMenu: false },
    { path: 'signup', component: SignUp, name: 'Sign Up', showInMenu: false },
    { path: 'pagamento', component: PagamentoMercadoPago, name: 'Gerar Link', showInMenu: true },
    { path: 'finaliza-pagamento', component: FinalizaPagamento, name: 'Finaliza Pagamento', showInMenu: false },
    { path: 'relatorio-pagamento', component: PagamentoList, name: 'Rel. Transações', showInMenu: true },
    { path: 'relatorio-pagamento/:id', component: PagamentoDetalhado, name: 'Transação', showInMenu: false },
    { path: 'ForgotPassword', component: ForgotPassword, name: 'Forgot Password', showInMenu: false },
    { path: 'RecoveryPassword/:id', component: RecoveryPassword, name: 'Recovery Password', showInMenu: false },
    // { path: 'add-company', component: AddCompany, name: 'Add Company', showInMenu: true },
    // { path: 'company', component: CompanyList, name: 'Company List', showInMenu: true },
];



// export const routes222 = [
//     { path: 'signin', component: SignIn, name: 'Sign In', showInMenu: false },
//     { path: 'signup', component: SignUp, name: 'Sign Up', showInMenu: false },
//     { path: 'add-company', component: AddCompany, name: 'Add Company', showInMenu: false },
//     { path: 'company', component: CompanyList, name: 'Company List', showInMenu: false },
//     { path: 'ForgotPassword', component: ForgotPassword, name: 'Forgot Password', showInMenu: false },
//     { path: 'Home', component: Home, name: 'Home', showInMenu: true },
//     { path: 'pagamento', component: PagamentoMercadoPago, name: 'Pagamento Mercado Pago', showInMenu: true },
//     { path: 'relatorio-pagamento', component: PagamentoList, name: 'tansactions', showInMenu: true },
//     { path: 'RecoveryPassword/:id', component: RecoveryPassword, name: 'Recovery Password', showInMenu: false }

// ];


export default function Index(props) {
    const { isLoggedIn, setLoggedIn } = props;

    const usePageTitle = (title) => {

        React.useEffect(() => {
            document.title = title;
        }, [title]);
    };

    return (
        <div>

            <BrowserRouter>
                <Routes>
                    <Route path="/" element={<GuestLayout isLoggedIn={isLoggedIn} setLoggedIn={setLoggedIn} />}>
                        {routes.map((route, index) => (
                            <Route key={index} path={route.path} element={<route.component setIsLoggedIn={setLoggedIn} isLoggedIn={isLoggedIn} usePageTitle={usePageTitle} />} />
                        ))}
                        <Route path="/404" element={<NotFound />} />
                        <Route path="/" element={<Navigate to='/relatorio-pagamento' />} />
                        <Route path='*' element={<Navigate to='/404' />} />
                    </Route>
                </Routes>
            </BrowserRouter>
        </div>
    );

    /* <BrowserRouter>
                    <Routes>
                        <Route path="/" element={<GuestLayout isLoggedIn={isLoggedIn} setLoggedIn={setLoggedIn} />}>
                            {routes.map((route, index) => (
                                <Route key={index} path={route.path} element={<route.component setIsLoggedIn={setLoggedIn} isLoggedIn={isLoggedIn} />} />
                            ))}
                        </Route>
                    </Routes>
                </BrowserRouter> */


    /* <div>
                <BrowserRouter>
                    <Routes>
                        <Route path="/" element={<GuestLayout isLoggedIn={isLoggedIn} setLoggedIn={setLoggedIn} />}>
                            <Route path="" element={<SignIn setIsLoggedIn={setLoggedIn} isLoggedIn={isLoggedIn} />} />
                            <Route path="signup" element={<SignUp setIsLoggedIn={setLoggedIn} />} />
                            <Route path="video" element={<VideoList setIsLoggedIn={setLoggedIn} />} />
                            <Route path="video/:id" element={<Video isLoggedIn={isLoggedIn} setIsLoggedIn={setLoggedIn} />} />
                            <Route path="add-company" element={<AddCompany isLoggedIn={isLoggedIn} setIsLoggedIn={setLoggedIn} />} />
                            <Route path="company" element={<CompanyList isLoggedIn={isLoggedIn} setIsLoggedIn={setLoggedIn} />} />
                            <Route path="ForgotPassword" element={<ForgotPassword isLoggedIn={isLoggedIn} setIsLoggedIn={setLoggedIn} />} />
                            <Route path="Home" element={<Home isLoggedIn={isLoggedIn} setIsLoggedIn={setLoggedIn} />} />
                        </Route>
                    </Routes>
                </BrowserRouter>
            </div> */

    // export default function Index(props) {
    //     const { isLoggedIn, setLoggedIn } = props
    //     return (
    //         <div>
    //             <Header isLoggedIn={isLoggedIn} />
    //             <BrowserRouter>
    //                 {isLoggedIn ?
    //                     <Routes>
    //                         <Route path="/video" element={<VideoList setLoggedIn={setLoggedIn} />}>
    //                         </Route>
    //                         <Route path="/video/:id" element={<Video setLoggedIn={setLoggedIn} />}>
    //                         </Route>
    //                     </Routes>
    //                     :
    //                     <Routes>
    //                         <Route path="/" element={<SignIn setIsLoggedIn={setLoggedIn} isLoggedIn={isLoggedIn} />}>
    //                         </Route>
    //                         <Route path="/signup" element={<SignUp setIsLoggedIn={setLoggedIn} />}>
    //                         </Route>
    //                     </Routes>
    //                 }
    //             </BrowserRouter>
    //         </div>

    //     )
}
