import { masks } from './constants';
export const normalizeInput = (value, ddi) => {
    if (!value) return value;
    const currentValue = value.replace(/[^\d]/g, '');

    // Obter a máscara correspondente ao DDI selecionado
    const mask = masks[ddi].mask;
    if (!mask) {
        // Se não houver uma máscara correspondente, retornar o valor atual sem formatação
        return currentValue;
    }

    let formattedValue = '';
    let maskIndex = 0;
    for (let i = 0; i < mask.length && maskIndex < currentValue.length; i++) {
        if (mask[i] === 'x') {
            // Se o caractere na máscara for 'x', adicionar o próximo dígito do valor atual
            formattedValue += currentValue[maskIndex];
            maskIndex++;
        } else {
            // Se o caractere na máscara não for 'x', adicionar o próprio caractere
            formattedValue += mask[i];
        }
    }
    return formattedValue;
};

export const handleChange = (event, state, setState) => {
    const { value } = event.target;
    //setPhone(value);
    setState({ ...state, phone: normalizeInput(value, state.ddi) });

};

export const handleDdiChange = (event, state, setState) => {
    const { value } = event.target;
    setState({ ...state, ddi: value, phone: '' });


};

export const validateInput = (value, ddi) => {
    if (!value) return "Required!";
    if (value.length !== masks[ddi].mask.length) return `Invalid phone format. ${masks[ddi].mask}`;
    return "";
};