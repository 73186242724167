import * as React from 'react';
import { styled, alpha } from '@mui/material/styles';
import { AppBar, Avatar, Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, InputBase, Menu, MenuItem, Modal, Slide, TextField, Toolbar, Tooltip, Typography } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import AdbIcon from '@mui/icons-material/Adb';
import axios from 'axios';
import { jwtDecode } from 'jwt-decode';
import { useLocation, useNavigate } from 'react-router-dom';
import { routes } from '../Index';
import { url } from '../utils/constants';
import { Image, Margin } from '@mui/icons-material';
import logoImage from '../../logo192.png';

// const settings = ['Sair'];
const settings = ['Perfil', 'Sair'];
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

const Search = styled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
        backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(1),
        width: 'auto',
    },
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: 'inherit',
    '& .MuiInputBase-input': {
        padding: theme.spacing(1, 1, 1, 0),
        paddingLeft: `calc(1em + ${theme.spacing(4)})`,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            width: '12ch',
            '&:focus': {
                width: '20ch',
            },
        },
    },
}));

async function renewToken() {
    try {
        const token = localStorage.getItem('token');
        const { data } = await axios.get(url + '/api/v1/renewToken', {
            headers: {
                Authorization: 'Bearer ' + token
            }
        });
        //console.log("Header: passou renewToken:" + data.token);
        if (data && data.token) {
            //console.log("Header: renovou o token");

            localStorage.setItem('token', data.token);
        }
    } catch (error) {
        console.error('Erro ao renovar o token:', error);
    }
}

async function checkTokenExpiry(navigate, isLoggedIn, setLoggedIn) {
    const token = await localStorage.getItem('token');
    try {
        if (token) {
            const decodedToken = jwtDecode(token);
            const currentTime = Date.now() / 1000;
            const expiryTime = decodedToken.exp;
            const timeRemaining = expiryTime - currentTime;
            const tenMinutesInSec = 10 * 60;
            //console.log('Tempo restante:', timeRemaining / 60);
            if (timeRemaining < tenMinutesInSec && timeRemaining > 0) {
                renewToken();
            } else if (timeRemaining < 0) {
                localStorage.removeItem('token');
                setLoggedIn(false);
                navigate('/signin');
            }
        }
    } catch (error) {
        console.error('Erro ao verificar a expiração do token:', error);

    }
}

export default function Header({ isLoggedIn, setLoggedIn }) {
    const [anchorElNav, setAnchorElNav] = React.useState(null);
    const [anchorElUser, setAnchorElUser] = React.useState(null);
    const [open, setOpen] = React.useState(false);
    const [openAlert, setOpenAlert] = React.useState(false);
    const [message, setMessage] = React.useState('Ainda não implementado');
    const [video, setVideo] = React.useState('');
    const [cover, setCover] = React.useState('');
    const [title, setTitle] = React.useState('');
    const navigate = useNavigate();
    const location = useLocation();
    const [fullname, setFullname] = React.useState('');
    const [role, setRole] = React.useState('');
    const [email, setEmail] = React.useState('');
    const menuRoutes = routes.filter(route => route.showInMenu);
    const titulo = 'PixTick';
    const [key, setKey] = React.useState(0);

    React.useEffect(() => {
        setFullname(localStorage.getItem('fullname'));
        setRole(localStorage.getItem('role'));
        setEmail(localStorage.getItem('email'));
        //console.log('Header: ', fullname, role, email);
        //console.log(location.pathname);
        switch (location.pathname) {
            case '/singin':

                return;
            default:
                const intervalId = setInterval(async () => {
                    await checkTokenExpiry(navigate, isLoggedIn, setLoggedIn);
                }, 60000);

                return () => clearInterval(intervalId);
        }
    }, [location, navigate, isLoggedIn, setLoggedIn]);

    React.useEffect(() => {
        setKey((prevKey) => prevKey + 1);
    }, [location]);

    const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget);
    };

    const handleOpenUserMenu = (event) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };

    const handleClose = () => {
        setAnchorElNav(null);
        setAnchorElUser(null);
    };

    const handleChangeCompanyAdd = (e) => {
        e.preventDefault();
        navigate('/add-company');
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const formData = new FormData();
        formData.append('title', title);
        formData.append('video', video);
        formData.append('cover', cover);
        const token = localStorage.getItem('token');
        await axios.post(process.env.URL + '/api/v1/video', formData, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
    };

    const handlePageClick = (page) => {
        navigate(`/${page.toLowerCase()}`);
        handleCloseNavMenu();
        window.location.reload(false);
    };

    const handleLogin = () => {
        navigate('/signin');
    }

    const handleMenuClick = (menuItem) => {
        handleCloseUserMenu();
        switch (menuItem) {
            case 'Perfil':
                handleProfileClick();
                break;
            case 'perfis':
                handleProfilesClick();
                break;
            case 'Account':
                handleAccountClick();
                break;
            case 'Dashboard':
                handleDashboardClick();
                break;
            case 'Sair':
                handleLogoutClick();
                break;
            case 'novoUsuario':
                handleNovoUsuario();
                break;
            default:
                break;
        }

    };

    const handleProfileClick = () => {
        // Lógica para lidar com o clique no botão "Profile"
        navigate('/perfil');
        //setOpenAlert(true);
        console.log("Profile clicked");
    };
    const handleProfilesClick = () => {
        // Lógica para lidar com o clique no botão "Profile"
        navigate('/perfis');
        //setOpenAlert(true);
        console.log("Profile clicked");
    };

    const handleAccountClick = () => {
        // Lógica para lidar com o clique no botão "Account"
        setOpenAlert(true);
        console.log("Account clicked");
    };

    const handleDashboardClick = () => {
        // Lógica para lidar com o clique no botão "Dashboard"
        setOpenAlert(true);
        console.log("Dashboard clicked");
    };

    const handleLogoutClick = () => {
        localStorage.removeItem('token');
        navigate('/signin');
        setLoggedIn(false);

    };

    const handleNovoUsuario = () => {
        navigate('/signup');
    }

    const renderHeaderButtonLogin = () => {
        switch (location.pathname) {
            case '/signin':
                return <></>
                break;
            default:
                return <Button variant="contained" onClick={handleLogin}>Entrar</Button>
        }
    }

    const handleCloseAlert = () => {

        setOpenAlert(false);

    }

    const handleNavigateRelatorioPagamento = () => {
        navigate('/relatorio-pagamento');
        window.location.reload(false);
    }


    return (
        <Box sx={{ flexGrow: 1 }}>
            <AppBar position="fixed">
                <Toolbar>
                    <Box
                        component="div"
                        sx={{
                            display: { xs: 'none', md: 'flex' },
                        }}
                    >
                        < img src={logoImage} width={30} height={30} style={{ margin: '10px' }} /></Box>
                    <Typography onClick={handleNavigateRelatorioPagamento} variant="h5" sx={{ mr: 2, display: { xs: 'none', md: 'flex' }, fontFamily: 'monospace', fontWeight: 700, letterSpacing: '.3rem', color: 'inherit', textDecoration: 'none' }}>{titulo}</Typography>
                    {isLoggedIn && <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
                        <IconButton size="large" aria-label="account of current user" aria-controls="menu-appbar" aria-haspopup="true" onClick={handleOpenNavMenu} color="inherit"><MenuIcon /></IconButton>
                        <Menu id="menu-appbar" anchorEl={anchorElNav} anchorOrigin={{ vertical: 'top', horizontal: 'right' }} keepMounted transformOrigin={{ vertical: 'top', horizontal: 'right' }} open={Boolean(anchorElNav)} onClose={handleCloseNavMenu}>
                            {menuRoutes.map((page) => (
                                <MenuItem divider key={page.name} onClick={() => handlePageClick(page.path)}><Typography textAlign="center">{page.name}</Typography></MenuItem>
                            ))}
                        </Menu>
                    </Box>}
                    {false && <img src={logoImage} width={45} height={45} style={{ margin: '10px' }} />}
                    <Box
                        component="div"
                        sx={{
                            display: { xs: 'flex', md: 'none' },
                        }}
                    >
                        < img src={logoImage} width={30} height={30} style={{ margin: '10px' }} /></Box>
                    <Typography onClick={handleNavigateRelatorioPagamento} variant="h7" noWrap component="a" href="#app-bar-with-responsive-menu" sx={{ mr: 2, display: { xs: 'flex', md: 'none' }, flexGrow: 1, fontFamily: 'monospace', fontWeight: 700, letterSpacing: '.3rem', color: 'inherit', textDecoration: 'none' }}>{titulo}</Typography>
                    <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
                        {isLoggedIn && menuRoutes.map((page) => (
                            <Button key={page.name} onClick={() => handlePageClick(page.path)} sx={{ my: 2, color: 'white', display: 'block' }}>{page.name}</Button>
                        ))}
                    </Box>
                    {isLoggedIn && (
                        <>
                            {false && <Search>
                                <StyledInputBase placeholder="Search…" inputProps={{ 'aria-label': 'search' }} />
                            </Search>}
                            <Box sx={{ flexGrow: 0, m: 1 }}>
                                <Tooltip title="Open settings">
                                    <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                                        <Avatar alt={fullname} src="/static/images/avatar/2.jpg" />
                                    </IconButton>
                                </Tooltip>
                                <Menu id="menu-appbar" anchorEl={anchorElUser} anchorOrigin={{ vertical: 'top', horizontal: 'right' }} keepMounted transformOrigin={{ vertical: 'top', horizontal: 'right' }} open={Boolean(anchorElUser)} onClose={handleCloseUserMenu}>
                                    <Typography variant="h6" align="center" sx={{ p: 2 }}>
                                        {fullname}
                                    </Typography>
                                    {role == 'ADMIN' && <MenuItem onClick={() => handleMenuClick('novoUsuario')}><Typography textAlign="center">Novo Usuário</Typography></MenuItem>}
                                    {role == 'ADMIN' && <MenuItem onClick={() => handleMenuClick('perfis')}><Typography textAlign="center">Perfis</Typography></MenuItem>}
                                    {settings.map((setting) => (
                                        <MenuItem key={setting} onClick={() => handleMenuClick(setting)}><Typography textAlign="center">{setting}</Typography></MenuItem>
                                    ))}
                                </Menu>
                            </Box>

                            { }
                        </>
                    )}
                    {!isLoggedIn && (<>
                        {renderHeaderButtonLogin()}
                    </>)}
                </Toolbar>
            </AppBar>
            <Dialog
                open={openAlert}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleCloseAlert}
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle>{"Alerta"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        {message}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseAlert}>Disagree</Button>
                    <Button onClick={handleCloseAlert}>Agree</Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
}
