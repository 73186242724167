// FilterComponent.js
import React from 'react';
import { TextField, Box, Grid, Select, MenuItem, Button, InputLabel, FormControl, Checkbox, ListItemText, Input, Typography, InputAdornment, IconButton } from '@mui/material';
import { Close, Filter, Filter1, Filter3Outlined, FilterList } from '@mui/icons-material';

const FilterComponentVertical = ({ filters, setFilters, onFilter, idpdvs }) => {

  const handleSelectChange = (event) => {
    const value = event.target.value;
    if (value[value.length - 1] === 'all') {
      setFilters((prevFilters) => ({
        ...prevFilters,
        idpdvs: filters.idpdvs.length === idpdvs.length ? [] : idpdvs.map((user) => user.idpdv),
      }));
    } else {
      setFilters((prevFilters) => ({
        ...prevFilters,
        idpdvs: value,
      }));
    }
  };


  const handleClear = (field) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [field]: ''
    }));
  };


  //const isAllSelected = filters.idpdvs.includes('all');

  const handleSubmit = (e) => {
    e.preventDefault();
    onFilter();
  }

  const isAllSelected = filters.idpdvs.length === idpdvs.length;
  return (
    <form onSubmit={handleSubmit}>
      <Grid container spacing={2} sx={{ marginTop: 2, display: 'flex', justifyContent: 'center' }}>
        <Grid item xs={10} display='flex' justifyContent='center' >
          <Typography variant="h6" gutterBottom component="div">
            Pesquisar
          </Typography>
        </Grid>
        <Grid item xs={10}>
          <TextField
            fullWidth
            label="Descrição"
            variant="outlined"
            value={filters.descricao}
            onChange={(e) => setFilters({ ...filters, descricao: e.target.value })}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={() => handleClear('descricao')}>
                    {filters.descricao != '' && <Close />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={10} >
          <TextField
            fullWidth
            label="Nome"
            variant="outlined"
            value={filters.nome}
            onChange={(e) => setFilters({ ...filters, nome: e.target.value })}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={() => handleClear('nome')}>
                    {filters.nome != '' && <Close />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={10} >
          <TextField
            fullWidth
            label="Data Início"
            type="datetime-local"
            variant="outlined"
            value={filters.dataInicio}
            onChange={(e) => setFilters({ ...filters, dataInicio: e.target.value })}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={() => handleClear('dataInicio')}>
                    {filters.dataInicio != '' && <Close />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            InputLabelProps={{
              shrink: true,
            }}
            style={{ marginRight: '10px' }}
          />
        </Grid>
        <Grid item xs={10} >
          <TextField
            fullWidth
            label="Data Fim"
            type="datetime-local"
            variant="outlined"
            value={filters.dataFim}
            onChange={(e) => setFilters({ ...filters, dataFim: e.target.value })}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={() => handleClear('dataFim')}>
                    {filters.dataFim != '' && <Close />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            InputLabelProps={{
              shrink: true,
            }}
            style={{ marginRight: '10px' }}
          />
        </Grid>
        <Grid item xs={10}>
          <TextField
            fullWidth
            label="Telefone"
            variant="outlined"
            value={filters.telefone}
            onChange={(e) => setFilters({ ...filters, telefone: e.target.value })}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={() => handleClear('telefone')}>
                    {filters.telefone != '' && <Close />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            style={{ marginRight: '10px' }}
          />
        </Grid>
        {/* <Grid item xs={10}>
        <TextField
          fullWidth
          label="Descrição, Nome, id, Telefone"
          variant="outlined"
          value={filters.geral}
          onChange={(e) => setFilters({ ...filters, geral: e.target.value })}
          style={{ marginRight: '10px' }}
        />
      </Grid> */}
        <Grid item xs={10}>
          <Select
            fullWidth
            labelId='status-select-label'
            label="Status"
            variant="outlined"
            value={filters.status}
            onChange={(e) => setFilters({ ...filters, status: e.target.value })}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={() => handleClear('status')}>
                    {filters.descricao != '' && <Close />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            style={{ marginRight: '10px' }}
          >
            <MenuItem value="Todos">Todos</MenuItem>
            <MenuItem value="PAGO">RECEBIDO</MenuItem>
            <MenuItem value="Pendente">PENDENTE</MenuItem>
            <MenuItem value="Expirado">EXPIRADO</MenuItem>
          </Select>
        </Grid>
        <Grid item xs={10}>
          {/* <InputLabel id="transacoes-select-label">Transações por página</InputLabel> */}
          <Select
            fullWidth
            labelId='transacoes-select-label'
            label="Transações por página"
            variant="outlined"
            value={filters.itensPagina}
            onChange={(e) => setFilters({ ...filters, itensPagina: e.target.value })}
            style={{ marginRight: '10px' }}
          >
            <MenuItem value={20}>20 Transações/Página</MenuItem>
            <MenuItem value={50}>50 Transações/Página</MenuItem>
            <MenuItem value={100}>100 Transações/Página</MenuItem>
          </Select>
        </Grid>

        {localStorage.getItem('role') === 'ADMIN' && <Grid item xs={10} >
          {(
            <FormControl fullWidth>
              <InputLabel id="idpdvs-select-label">IDPDVs</InputLabel>
              <Select
                labelId="idpdvs-select-label"
                id="idpdvs-select"
                multiple
                value={filters.idpdvs}
                onChange={handleSelectChange}
                input={<Input />}
                renderValue={(selected) => selected.join(', ')}
              >
                <MenuItem value="all">
                  <Checkbox checked={isAllSelected} indeterminate={filters.idpdvs.length > 0 && !isAllSelected} />
                  <ListItemText primary="Marcar Todos" />
                </MenuItem>
                {idpdvs?.map((user) => (
                  <MenuItem key={user.idpdv} value={user.idpdv}>
                    <Checkbox checked={filters.idpdvs.indexOf(user.idpdv) > -1} />
                    <ListItemText primary={`${user.empresa} - ${user.idpdv}`} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
        </Grid>}
        <Grid item xs={10} >
          <Button fullWidth type='submit' variant="contained">Filtrar</Button>
        </Grid>
        {/* Repita para os outros campos */}
      </Grid>
    </form >
  );
};

export default FilterComponentVertical; 